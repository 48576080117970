// Import libraries
import React, { useEffect, useState } from "react";
import {ApexOptions} from "apexcharts";

// Import components
import Chart from "react-apexcharts";
import { DASH_ALG_WerkgeversMetSubsidieRegelingenKwartaal } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";

function UniqueEmployersCombi(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_ALG_WerkgeversMetSubsidieRegelingenKwartaal[] = [];

    let [options, setOptions]: any = useState({ });

    let [busy, setBusy] = useState(true);

    let startJaar = DashboardYear - 1;
    let eindJaar = DashboardYear;

    useEffect(() => {
        fetch(FormatString(ApiUrls.DASH_ALG_WerkgeversMetSubsidieRegelingenKwartaal, ApiBaseURL, SectorId.toString(), startJaar.toString() + "-1-1", eindJaar.toString() + "-12-31", loginGuid, userGuid, params.kw))
            .then(response => response.json())
            .then(json => {

                obj = JSON.parse(JSON.stringify(json));

                const sumKwartaal1startJaar = obj.filter(f => f.Jaar == startJaar).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal2startJaar = obj.filter(f => f.Jaar == startJaar).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal3startJaar = obj.filter(f => f.Jaar == startJaar).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal4startJaar = obj.filter(f => f.Jaar == startJaar).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal1eindJaar = obj.filter(f => f.Jaar == eindJaar).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal2eindJaar = obj.filter(f => f.Jaar == eindJaar).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal3eindJaar = obj.filter(f => f.Jaar == eindJaar).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal4eindJaar = obj.filter(f => f.Jaar == eindJaar).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);

                setOptions({
                    ...options,
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'round',
                        colors: undefined,
                        width: 5,
                        dashArray: 0,
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    chart: {
                        id: 'UniqueEmployersCombi',
                        toolbar: {
                            show: false
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            colors: ['#fff']
                        },
                        background: {
                            enabled: true,
                            foreColor: 'black',
                            padding: 4,
                            borderWidth: 1,
                            borderColor: 'black',
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            borderRadiusApplication: 'end',
                            dataLabels: {
                                position: 'bottom'
                            }
                        }
                    },
                    yaxis: {
                        forceNiceScale: true,
                        show: true
                    },
                    series: [
                        {
                            name: 'Unieke werkgevers ' + startJaar.toString(),
                            color: "#FFB400",
                            type: 'line',
                            data: [
                                {
                                    x: 'Q1',
                                    y: sumKwartaal1startJaar
                                },
                                {
                                    x: 'Q2',
                                    y: sumKwartaal2startJaar
                                },
                                {
                                    x: 'Q3',
                                    y: sumKwartaal3startJaar
                                },
                                {
                                    x: 'Q4',
                                    y: sumKwartaal4startJaar
                                }
                            ]
                        },
                        {
                            name: 'Unieke werkgevers ' + eindJaar.toString(),
                            color: "#064789",
                            type: 'bar',
                            data: [
                                {
                                    x: 'Q1',
                                    y: sumKwartaal1eindJaar
                                },
                                {
                                    x: 'Q2',
                                    y: sumKwartaal2eindJaar
                                },
                                {
                                    x: 'Q3',
                                    y: sumKwartaal3eindJaar
                                },
                                {
                                    x: 'Q4',
                                    y: sumKwartaal4eindJaar
                                }
                            ]
                        }
                    ],
                });

                setBusy(false);
            }
            );
    }, [])

    if (busy)
    return <></>

  return (
    <>
      <div className="screen">
        <Chart options={options}
               series={options?.series}
                  type="bar"/>
      </div>
      <div className="print print-scale">
        <Chart options={options}
                series={options?.series}
                type="bar"
                width="360"
                height="300"
            />
      </div>
    </>
  )
}

export default UniqueEmployersCombi;
