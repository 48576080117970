// Import libraries
import React, { useState } from "react";

// Import components
import { Col, Row } from 'react-bootstrap';
import { DASH_ALG_WerkgeversInDeSector } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";

function EmployersInSector(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_ALG_WerkgeversInDeSector[] = [];
    let [aantal, setAantal] = useState(0);
    let [busy, setBusy] = useState(true);

    fetch(FormatString(ApiUrls.DASH_ALG_WerkgeversInDeSector, ApiBaseURL, SectorId.toString(), DashboardYear.toString() + "-12-31", loginGuid, userGuid, params.kw))
        .then(response => response.json())
        .then(json => {

            obj = JSON.parse(JSON.stringify(json));

            setAantal(obj.map(item => item.AantalWerkgevers).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0));

            setBusy(false);
        }
    );

    if (busy)
    return <></>

    return (
        <>
            <div className="card sun bg-white border border-gray-200 rounded-lg shadow">
                <div className="c-title">
                    <h6 className="mb-0 hfont6">Unieke werkgevers in sector</h6>
                </div>
                <Row className="c-row">
                    <Col xs={12} sm={9} xl={7}>
                        <h1 className="mb-0 hfont1">{aantal}</h1>
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default EmployersInSector;
