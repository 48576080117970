// Import libraries
import React, { useState } from "react";

// Import components
import Chart from "react-apexcharts";
import { DASH_ALG_WerknemersMetSubsidieRegelingenKwartaal } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";
import { Col, Table } from "react-bootstrap";

function UniqueEmployeesFourYearsTable(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_ALG_WerknemersMetSubsidieRegelingenKwartaal[] = [];

    let [busy, setBusy] = useState(true);
    let [empty, setEmpty] = useState(true);

    let [tabel, setTable] = useState("");

    let jaar1 = DashboardYear - 3;
    let jaar2 = DashboardYear - 2;
    let jaar3 = DashboardYear - 1;
    let jaar4 = DashboardYear;

    fetch(FormatString(ApiUrls.DASH_ALG_WerknemersMetSubsidieRegelingenKwartaal, ApiBaseURL, SectorId.toString(), jaar1.toString() + "-1-1", jaar4.toString() + "-12-31", loginGuid, userGuid, params.kw))
        .then(response => response.json())
        .then(json => {

            obj = JSON.parse(JSON.stringify(json));

            obj = obj.filter(f => f.RegelingId == params.RegelingId);

            setEmpty(obj.length == 0);

            const sumKwartaal1Jaar1 = obj.filter(f => f.Jaar == jaar1).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal2Jaar1 = obj.filter(f => f.Jaar == jaar1).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal3Jaar1 = obj.filter(f => f.Jaar == jaar1).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal4Jaar1 = obj.filter(f => f.Jaar == jaar1).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal1Jaar2 = obj.filter(f => f.Jaar == jaar2).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal2Jaar2 = obj.filter(f => f.Jaar == jaar2).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal3Jaar2 = obj.filter(f => f.Jaar == jaar2).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal4Jaar2 = obj.filter(f => f.Jaar == jaar2).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal1Jaar3 = obj.filter(f => f.Jaar == jaar3).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal2Jaar3 = obj.filter(f => f.Jaar == jaar3).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal3Jaar3 = obj.filter(f => f.Jaar == jaar3).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal4Jaar3 = obj.filter(f => f.Jaar == jaar3).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal1Jaar4 = obj.filter(f => f.Jaar == jaar4).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal2Jaar4 = obj.filter(f => f.Jaar == jaar4).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal3Jaar4 = obj.filter(f => f.Jaar == jaar4).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);
            const sumKwartaal4Jaar4 = obj.filter(f => f.Jaar == jaar4).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0);

            tabel = "";
            tabel += "<tr><td align='left'>Q1</td><td nowrap> " + sumKwartaal1Jaar1.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal1Jaar2.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal1Jaar3.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal1Jaar4.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td></tr>";
            tabel += "<tr><td align='left'>Q2</td><td nowrap>" + sumKwartaal2Jaar1.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal2Jaar2.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal2Jaar3.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal2Jaar4.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td></tr>";
            tabel += "<tr><td align='left'>Q3</td><td nowrap>" + sumKwartaal3Jaar1.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal3Jaar2.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal3Jaar3.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal3Jaar4.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td></tr>";
            tabel += "<tr><td align='left'>Q4</td><td nowrap>" + sumKwartaal4Jaar1.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal4Jaar2.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal4Jaar3.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td nowrap>" + sumKwartaal4Jaar4.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td></tr>";

            setTable(tabel);
            setBusy(false);
        }
        );

    if (busy || empty)
        return <></>

    return (
        <Col xl={6} md={12} className="mb-2 col-print-6">
            <div className="card bg-white border border-gray-200 rounded-lg shadow p-3 h-auto">
                <h6 className="flex-fill hfont6">{params.RegelingNaam}: Unieke werknemers, cumulatief per jaar ({DashboardYear - 3}-{DashboardYear})</h6>
                <div className="table-responsive">
                    <Table hover size="sm" className="text-end mb-0 gras-table">
                        <thead>
                            <tr>
                                <th className="text-start">Kwartaal</th>
                                <th>{jaar1}</th>
                                <th>{jaar2}</th>
                                <th>{jaar3}</th>
                                <th>{jaar4}</th>
                            </tr>
                        </thead>
                        <tbody dangerouslySetInnerHTML={{ __html: tabel }} />
                    </Table>
                </div>
            </div>
        </Col>
    )
}

export default UniqueEmployeesFourYearsTable;
