// Import libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {ApexOptions} from "apexcharts";

// Import components
import App from './App';

// Import styling
import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

interface IWindow extends Window {
  Apex: ApexOptions
}

declare let window: IWindow;

window.Apex = {
  chart: {
    toolbar: {
      show: true
    },
    background: '#fff'
  },
  title: {
    style: {
      fontSize: '24px',
    }
  },
  subtitle: {
    style: {
      fontSize: '14px',
    }
  },
  colors: ['#008FFB'],
  fill: {
    opacity: 1
  },
}
