// Import libraries
import React, { useState } from "react";

import { Col, Row } from 'react-bootstrap';
import { DASH_ALG_WerknemersMetEenRegelingInJaar } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";
import iconUp from "../Images/arrow-up.svg";
import iconDown from "../Images/arrow-down.svg";

function EmployeesWithRegeling(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_ALG_WerknemersMetEenRegelingInJaar[] = [];

    let [aantalPrevious, setAantalPrevious] = useState(0);
    let [aantal, setAantal] = useState(0);
    let [busy, setBusy] = useState(true);

    fetch(FormatString(ApiUrls.DASH_ALG_WerknemersMetEenRegelingInJaar, ApiBaseURL, SectorId.toString(), (DashboardYear - 1).toString() + "-1-1", DashboardYear.toString() + "-12-31", loginGuid, userGuid, params.kw))
        .then(response => response.json())
        .then(json => {

            obj = JSON.parse(JSON.stringify(json));

            setAantalPrevious(obj.filter(f => f.Jaar == DashboardYear - 1).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0));

            setAantal(obj.filter(f => f.Jaar == DashboardYear).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                return accumulation + current;
            }, 0));

            setBusy(false);
        }
    );

    if (busy)
    return <></>

    return (
        <>
            <div className="card gras bg-white border border-gray-200 rounded-lg shadow">
                <div className="c-title">
                    <h6 className="mb-0 hfont6">Unieke werknemers met regeling</h6>
                </div>
                <Row className="c-row">
                    <Col xl={9} md={12} className="col-print-6">
                        <h1 className="mb-0 hfont1">{aantal}</h1>
                    </Col>
                    <Col xl={3} md={12} className="col-print-6">
                        <img src={aantal > aantalPrevious ? iconUp : iconDown} className="percentageLogo" />
                        <label className="percentageText">
                            {((aantal / aantalPrevious * 100) - 100).toFixed(0)}%
                        </label>
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default EmployeesWithRegeling;
