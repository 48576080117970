// Import libraries
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';

// Import styling
import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css"


// Import components
import { FormatString } from "./components/cas/Functions/ApiFunctions";
import { ApiBaseURL, LoginUrl, ApiUrls } from "./components/cas/Urls/ApiUrls";
import { useEffect, useState } from "react";
import Home from "./components/Home";
import Dierhouderij from "./components/cas/Dierhouderij";
import Hoveniers from './components/cas/Hoveniers';
import Loonwerk from './components/cas/Loonwerk';
import Bedrijfsverzorging from "./components/cas/Bedrijfsverzorging";
import Bloembollengroothandel from "./components/cas/Bloembollengroothandel";
import BosEnNatuur from "./components/cas/BosEnNatuur";
import Glastuinbouw from "./components/cas/Glastuinbouw";
import Groenvoederdrogerijen from "./components/cas/Groenvoederdrogerijen";
import OpenTeeltenBloembollen from "./components/cas/OpenTeeltenBloembollen";
import OpenTeeltenBoomkwekerij from "./components/cas/OpenTeeltenBoomkwekerij";
import OpenTeeltenLandbouw from "./components/cas/OpenTeeltenLandbouw";
import Paddenstoelenteelt from "./components/cas/Paddenstoelenteelt";
import OpenTeeltenTuinbouw from "./components/cas/OpenTeeltenTuinbouw";

function App() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userid = urlParams.get('userid') ?? "0";
    const name = urlParams.get('name') ?? "0";
    const rnd = urlParams.get('rnd') ?? "0";
    const year = urlParams.get('year') ?? "0";
    const lg = urlParams.get('lg') ?? "0";
    const kw = urlParams.get('kw') ?? "2";
    const print = urlParams.get('print') ?? "0";

    let [auth, setauth] = useState(false);

    useEffect(() => {
        fetch(FormatString(ApiUrls.GetAuthorized, ApiBaseURL, userid, name, rnd, lg)).then(response => response.json()).then(json => {
            if (!json)
            {
                window.location.href = LoginUrl;
            }
            else
            {
                setauth(true);
            }
        });
    }, [])

    if (!auth)
        return <></>

    if (print == "1")
    {
        setTimeout(() => {

            const filename = 'download1.pdf';
            const node = document.documentElement;

            node.innerHTML = node.innerHTML.replaceAll("col-print", "col-print-download");
            node.innerHTML = node.innerHTML.replaceAll("\"print", "\"print-download");
            node.innerHTML = node.innerHTML.replaceAll("screen", "screen-download");
            node.innerHTML = node.innerHTML.replaceAll("bclass", "bclass-download");
            node.innerHTML = node.innerHTML.replaceAll("menu", "menu-download");
            node.innerHTML = node.innerHTML.replaceAll("hfont1", "hfont1-download");
            node.innerHTML = node.innerHTML.replaceAll("hfont2", "hfont2-download");
            node.innerHTML = node.innerHTML.replaceAll("hfont3", "hfont3-download");
            node.innerHTML = node.innerHTML.replaceAll("hfont4", "hfont4-download");
            node.innerHTML = node.innerHTML.replaceAll("hfont5", "hfont5-download");
            node.innerHTML = node.innerHTML.replaceAll("hfont6", "hfont6-download");
            node.innerHTML = node.innerHTML.replaceAll("card", "card card-download");

            html2canvas(node, {scale: 2})
                .then(function (canvas) {

                    var imgData = canvas.toDataURL('image/png');
                    var imgWidth = 462; // 210;
                    var pageHeight = 649; // 295;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF('p', 'mm');
                    var position = 0; // give some top padding to first page

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position += heightLeft - imgHeight; // top padding for other pages
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }
                    doc.save(filename);
                });

        }, 20000);
    }
  return (
    <Router>
      <div className="app">
        <div className="page-wrapper">
            <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/cas/Bedrijfsverzorging" element={<Bedrijfsverzorging Year={year} Kw={kw} Rnd={rnd} Lg={lg}  />} />
                      <Route path="/cas/Bloembollengroothandel" element={<Bloembollengroothandel Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/BosEnNatuur" element={<BosEnNatuur Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/Dierhouderij" element={<Dierhouderij Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/Glastuinbouw" element={<Glastuinbouw Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/Groenvoederdrogerijen" element={<Groenvoederdrogerijen Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/Hoveniers" element={<Hoveniers Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />                
                      <Route path="/cas/Loonwerk" element={<Loonwerk Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/OpenTeeltenBloembollen" element={<OpenTeeltenBloembollen Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/OpenTeeltenBoomkwekerij" element={<OpenTeeltenBoomkwekerij Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/OpenTeeltenLandbouw" element={<OpenTeeltenLandbouw Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/OpenTeeltenTuinbouw" element={<OpenTeeltenTuinbouw Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
                      <Route path="/cas/Paddenstoelenteelt" element={<Paddenstoelenteelt Year={year} Kw={kw} Rnd={rnd} Lg={lg} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
export default App;
