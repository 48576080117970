// Import libraries
import React, { useEffect, useState } from "react";
import {ApexOptions} from "apexcharts";

// Import components
import Chart from "react-apexcharts";
import { DASH_ALG_WerknemersMetSubsidieRegelingenKwartaal } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";
import { Col } from "react-bootstrap";

function UniqueEmployeesFourYear(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_ALG_WerknemersMetSubsidieRegelingenKwartaal[] = [];

    let [options, setOptions]: any = useState({
        chart: {
            id: 'UniqueEmployeesFourYear',
            toolbar: {
                show: false
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        plotOptions: {
            bar: {
                distributed: true,
                borderRadius: 10,
                borderRadiusApplication: 'end',
                dataLabels: {
                    total: {
                        enabled: true
                    }
                }
            }
        },
        legend: {
            show: false
        },
        colors: ['#158A42', '#158A42', '#158A42', '#158A42',
                '#612812', '#612812', '#612812', '#612812',
                '#158A42', '#158A42', '#158A42', '#158A42',
                 '#612812', '#612812', '#612812', '#612812'],
        yaxis: {
            forceNiceScale: true,
            show: true
        }
    });

    let [busy, setBusy] = useState(true);
    let [empty, setEmpty] = useState(true);

    let jaar1 = DashboardYear - 3;
    let jaar2 = DashboardYear - 2;
    let jaar3 = DashboardYear - 1;
    let jaar4 = DashboardYear;

    useEffect(() => {
        fetch(FormatString(ApiUrls.DASH_ALG_WerknemersMetSubsidieRegelingenKwartaal, ApiBaseURL, SectorId.toString(), jaar1.toString() + "-1-1", jaar4.toString() + "-12-31", loginGuid, userGuid, params.kw))
            .then(response => response.json())
            .then(json => {

                obj = JSON.parse(JSON.stringify(json));

                obj = obj.filter(f => f.RegelingId == params.RegelingId);

                setEmpty(obj.length == 0);

                const sumKwartaal1Jaar1 = obj.filter(f => f.Jaar == jaar1).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal2Jaar1 = obj.filter(f => f.Jaar == jaar1).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal3Jaar1 = obj.filter(f => f.Jaar == jaar1).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal4Jaar1 = obj.filter(f => f.Jaar == jaar1).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal1Jaar2 = obj.filter(f => f.Jaar == jaar2).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal2Jaar2 = obj.filter(f => f.Jaar == jaar2).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal3Jaar2 = obj.filter(f => f.Jaar == jaar2).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal4Jaar2 = obj.filter(f => f.Jaar == jaar2).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal1Jaar3 = obj.filter(f => f.Jaar == jaar3).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal2Jaar3 = obj.filter(f => f.Jaar == jaar3).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal3Jaar3 = obj.filter(f => f.Jaar == jaar3).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal4Jaar3 = obj.filter(f => f.Jaar == jaar3).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal1Jaar4 = obj.filter(f => f.Jaar == jaar4).map(item => item.Kwartaal1).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal2Jaar4 = obj.filter(f => f.Jaar == jaar4).map(item => item.Kwartaal2).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal3Jaar4 = obj.filter(f => f.Jaar == jaar4).map(item => item.Kwartaal3).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const sumKwartaal4Jaar4 = obj.filter(f => f.Jaar == jaar4).map(item => item.Kwartaal4).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);

                setOptions({
                    ...options,
                    xaxis: {
                        group: {
                            style: {
                                fontWeight: 700
                            },
                            groups: [
                                { title: jaar1, cols: 4 },
                                { title: jaar2, cols: 4 },
                                { title: jaar3, cols: 4 },
                                { title: jaar4, cols: 4 },
                            ]
                        }
                    },
                    series: [
                        {
                            name: 'Unieke werknemers',
                            data: [
                                //Jaar 1
                                {
                                    x: 'Q1',
                                    y: sumKwartaal1Jaar1
                                },
                                {
                                    x: 'Q2',
                                    y: sumKwartaal2Jaar1
                                },
                                {
                                    x: 'Q3',
                                    y: sumKwartaal3Jaar1
                                },
                                {
                                    x: 'Q4',
                                    y: sumKwartaal4Jaar1
                                },
                                //Jaar 2
                                {
                                    x: 'Q1',
                                    y: sumKwartaal1Jaar2
                                },
                                {
                                    x: 'Q2',
                                    y: sumKwartaal2Jaar2
                                },
                                {
                                    x: 'Q3',
                                    y: sumKwartaal3Jaar2
                                },
                                {
                                    x: 'Q4',
                                    y: sumKwartaal4Jaar2
                                },
                                //Jaar 3
                                {
                                    x: 'Q1',
                                    y: sumKwartaal1Jaar3
                                },
                                {
                                    x: 'Q2',
                                    y: sumKwartaal2Jaar3
                                },
                                {
                                    x: 'Q3',
                                    y: sumKwartaal3Jaar3
                                },
                                {
                                    x: 'Q4',
                                    y: sumKwartaal4Jaar3
                                },
                                //Jaar 4
                                {
                                    x: 'Q1',
                                    y: sumKwartaal1Jaar4
                                },
                                {
                                    x: 'Q2',
                                    y: sumKwartaal2Jaar4
                                },
                                {
                                    x: 'Q3',
                                    y: sumKwartaal3Jaar4
                                },
                                {
                                    x: 'Q4',
                                    y: sumKwartaal4Jaar4
                                },
                            ]
                        }
                    ],
                });

                setBusy(false);
            }
            );
    }, [])

    if (busy || empty)
    return <></>

  return (
      <>
          <Col xl={6} md={12} className="mb-2 col-print-12">
              <div className="card bg-white border border-gray-200 rounded-lg shadow d-flex justify-content-between">
                  <div className="d-flex p-3">
                      <h6 className="flex-fill hfont6">{params.RegelingNaam}: Unieke werknemers, cumulatief per jaar ({DashboardYear - 3}-{DashboardYear})</h6>
                  </div>
                  <div>
                      <div className="screen">
                          <Chart options={options}
                              series={options?.series}
                              type="bar" />
                      </div>
                      <div className="print">
                          <Chart options={options}
                              series={options?.series}
                              type="bar"
                              width="720"
                              height="400"
                          />
                      </div>
                  </div>
              </div>
          </Col>
    </>
  )
}

export default UniqueEmployeesFourYear;
