// Import libraries
import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";

// Import components
import Chart from "react-apexcharts";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";
import { DASH_ALG_UitgavenPerRegelingPerJaar } from "../Models/ApiModels";

function FinanceChart(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_ALG_UitgavenPerRegelingPerJaar[] = [];
    let [busy, setBusy] = useState(true);

    let [options, setOptions]: any = useState({
        colors: ['#FFB400', '#064789', '#158A42', '#612812', '#3F7CAC',
            '#5879B7', '#5DB976',  '#8F655D', '#FFE558'],
        chart: {
            id: 'financeChart',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#fff']
            },
            background: {
                enabled: true,
                foreColor: 'black',
                padding: 4,
                borderWidth: 1,
                borderColor: 'black',
                dropShadow: {
                    enabled: false
                }
            },
            dropShadow: {
                enabled: false
            }
        },
        fill: {
            type: 'solid',
            opacity: 1
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    minAngleToShowLabel: 0,
                },
            }
        }
    });

    useEffect(() => {
        fetch(FormatString(ApiUrls.DASH_ALG_UitgavenPerRegelingPerJaar, ApiBaseURL, SectorId.toString(), DashboardYear.toString() + "-1-1", DashboardYear.toString() + "-12-31", "0", loginGuid, userGuid, params.kw))
            .then(response => response.json())
            .then(json => {

                obj = JSON.parse(JSON.stringify(json));

                setOptions({
                    ...options,
                    series: obj.map(obj => obj.TotaalUitgekeerd),
                    labels: obj.map(obj => obj.Regeling),
                });

                setBusy(false);
            }
            );
    }, [])
    if (busy)
        return <></>
  
    return (
        <>
            <div className="screen">
                <Chart options={options}
                    series={options?.series}
                    type="donut" />
            </div>
            <div className="print print-scale">
                <Chart options={options}
                    series={options?.series}
                    type="donut"
                    width="360"
                    height="500"
                />
            </div>
        </>
    )
}

export default FinanceChart;

