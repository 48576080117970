// Import libraries
import React, { useState } from "react";

// Import components
import Chart from "react-apexcharts";
import { DASH_CUR_OpleiderAantalAanvragenPerJaar } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";
import { Table } from "react-bootstrap";

function CursusOpleiders(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_CUR_OpleiderAantalAanvragenPerJaar[] = [];

    let [busy, setBusy] = useState(true);

    let [tabel, setTable] = useState("");

    fetch(FormatString(ApiUrls.DASH_CUR_OpleiderAantalAanvragenPerJaar, ApiBaseURL, SectorId.toString(), DashboardYear.toString() + "-1-1", DashboardYear.toString() + "-12-31", loginGuid, userGuid, params.kw))
        .then(response => response.json())
        .then(json => {

            obj = JSON.parse(JSON.stringify(json));

            tabel = "";
            for (var i = 0; i < obj.length; i++) {
                tabel +=
                    "<tr><td align='left'>" + obj[i].Opleidingsinstituten +
                    "</td><td>" + obj[i].AantalAanvragen +
                    "</td><td>&euro; " + (obj[i].Bedrag ?? 0).toLocaleString('nl-NL', { maximumFractionDigits: 0 }) +
                    "</td></tr>";
            }
            setTable(tabel);
            setBusy(false);
        }
    );

    if (busy)
    return <></>

  return (
      <div className="table-responsive">
          <Table hover size="sm" className="text-end mb-0 gras-table">
              <thead>
                  <tr>
                      <th className="text-start">Cursusinstituut</th>
                      <th>Aantal</th>
                      <th>Bedrag</th>
                  </tr>
              </thead>
              <tbody dangerouslySetInnerHTML={{ __html: tabel }} /> 
          </Table>
      </div>
  )
}

export default CursusOpleiders;
