export const ApiBaseURL = "https://www.colland-administratie.nl/Dashboard/Api/";
export const LoginUrl = "https://www.colland-administratie.nl/Account/Login";

//export const ApiBaseURL = "https://colland.accept.gbt.dev/Dashboard/Api/";
//export const LoginUrl = "https://colland.accept.gbt.dev/Account/Login";

export enum ApiUrls {
    GetAuthorized = "{0}GetAuthorized?userid={1}&name={2}&rnd={3}&lg={4}",
    DASH_ALG_UitgavenPerRegelingPerJaar = "{0}GetDASH_ALG_UitgavenPerRegelingPerJaar?ID={6}&sectorid={1}&startDate={2}&endDate={3}&regelingTypeId={4}&lg={5}&hj={7}",
    DASH_ALG_UitgavenPerRegelingPerKwartaal ="{0}GetDASH_ALG_UitgavenPerRegelingPerKwartaal?ID={6}&sectorid={1}&startDate={2}&endDate={3}&regelingTypeId={4}&lg={5}&hj={7}",
    DASH_ALG_UitputtingRegelingen = "{0}GetDASH_ALG_UitputtingRegelingen?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_ALG_WerkgeversAantalAanvragenPerJaar = "{0}GetDASH_ALG_WerkgeversAantalAanvragenPerJaar?ID={6}&sectorid={1}&startDate={2}&endDate={3}&regelingTypeId={4}&lg={5}&hj={7}",
    DASH_ALG_WerkgeversInDeSector = "{0}GetDASH_ALG_WerkgeversInDeSector?ID={4}&sectorid={1}&date={2}&lg={3}&hj={5}",
    DASH_ALG_WerkgeversMetEenRegelingInJaar = "{0}GetDASH_ALG_WerkgeversMetEenRegelingInJaar?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_ALG_WerkgeversMetSubsidieRegelingenKwartaal = "{0}GetDASH_ALG_WerkgeversMetSubsidieRegelingenKwartaal?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_ALG_WerkgeversProcentueelGebruik = "{0}GetDASH_ALG_WerkgeversProcentueelGebruik?ID={4}&sectorid={1}&&date={2}&lg={3}&hj={5}",
    DASH_ALG_WerknemersInDeSector = "{0}GetDASH_ALG_WerknemersInDeSector?ID={4}&sectorid={1}&date={2}&lg={3}&hj={5}",
    DASH_ALG_WerknemersMetEenRegelingInJaar = "{0}GetDASH_ALG_WerknemersMetEenRegelingInJaar?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_ALG_WerknemersMetSubsidieRegelingen = "{0}GetDASH_ALG_WerknemersMetSubsidieRegelingen?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_ALG_WerknemersMetSubsidieRegelingenKwartaal = "{0}GetDASH_ALG_WerknemersMetSubsidieRegelingenKwartaal?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_ALG_WerknemersProcentueelGebruik = "{0}GetDASH_ALG_WerknemersProcentueelGebruik?ID={4}&sectorid={1}&&date={2}&lg={3}&hj={5}",
    DASH_ALG_WerknermsLeeftijsverdelingInRegeling = "{0}GetDASH_ALG_WerknermsLeeftijsverdelingInRegeling?ID={6}&sectorid={1}&startDate={2}&endDate={3}&regelingTypeId={4}&lg={5}&hj={7}",
    DASH_ALG_WerknermsLeeftijsverdelingInSector = "{0}GetDASH_ALG_WerknermsLeeftijsverdelingInSector?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_BBL_OpleiderAantalAanvragenPerJaar = "{0}GetDASH_BBL_OpleiderAantalAanvragenPerJaar?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={65}",
    DASH_BBL_OpleidingAantalAanvragenPerJaar = "{0}GetDASH_BBL_OpleidingAantalAanvragenPerJaar?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_CUR_OpleiderAantalAanvragenPerJaar = "{0}GetDASH_CUR_OpleiderAantalAanvragenPerJaar?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}",
    DASH_CUR_OpleidingAantalAanvragenPerJaar = "{0}GetDASH_CUR_OpleidingAantalAanvragenPerJaar?ID={5}&sectorid={1}&startDate={2}&endDate={3}&lg={4}&hj={6}"
}
