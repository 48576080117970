// Import libraries
import React, { useEffect, useState } from "react";
import {ApexOptions} from "apexcharts";

// Import components
import Chart from "react-apexcharts";
import { DASH_ALG_WerknermsLeeftijsverdelingInRegeling } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";

function CursusAgesEmployees(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let objbase: DASH_ALG_WerknermsLeeftijsverdelingInRegeling[] = [];

    let [options, setOptions]: any = useState({});

    let [busy, setBusy] = useState(true);

    useEffect(() => {
        fetch(FormatString(ApiUrls.DASH_ALG_WerknermsLeeftijsverdelingInRegeling, ApiBaseURL, SectorId.toString(), DashboardYear.toString() + "-1-1", DashboardYear.toString() + "-12-31", "0", loginGuid, userGuid, params.kw))
            .then(response => response.json())
            .then(json => {

                objbase = JSON.parse(JSON.stringify(json));

                var  obj = objbase.filter(f => f.RegelingId == -1);

                const ageTotal = obj.map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);

                const age13_19 = obj.filter(f => f.Age >= 13 && f.Age <= 19).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age20_24 = obj.filter(f => f.Age >= 20 && f.Age <= 24).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age25_29 = obj.filter(f => f.Age >= 25 && f.Age <= 29).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age30_34 = obj.filter(f => f.Age >= 30 && f.Age <= 34).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age35_39 = obj.filter(f => f.Age >= 35 && f.Age <= 39).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age40_44 = obj.filter(f => f.Age >= 40 && f.Age <= 44).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age45_49 = obj.filter(f => f.Age >= 45 && f.Age <= 49).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age50_54 = obj.filter(f => f.Age >= 50 && f.Age <= 54).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age55_59 = obj.filter(f => f.Age >= 55 && f.Age <= 59).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age60_64 = obj.filter(f => f.Age >= 60).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age65 = obj.filter(f => f.Age >= 13 && f.Age <= 19).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);


                var objCur = objbase.filter(f => f.RegelingId == params.RegelingId);

                const ageTotalCur = objCur.map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);

                const age13_19cur = objCur.filter(f => f.Age >= 13 && f.Age <= 19).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age20_24cur = objCur.filter(f => f.Age >= 20 && f.Age <= 24).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age25_29cur = objCur.filter(f => f.Age >= 25 && f.Age <= 29).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age30_34cur = objCur.filter(f => f.Age >= 30 && f.Age <= 34).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age35_39cur = objCur.filter(f => f.Age >= 35 && f.Age <= 39).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age40_44cur = objCur.filter(f => f.Age >= 40 && f.Age <= 44).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age45_49cur = objCur.filter(f => f.Age >= 45 && f.Age <= 49).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age50_54cur = objCur.filter(f => f.Age >= 50 && f.Age <= 54).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age55_59cur = objCur.filter(f => f.Age >= 55 && f.Age <= 59).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age60_64cur = objCur.filter(f => f.Age >= 60).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);
                const age65cur = objCur.filter(f => f.Age >= 13 && f.Age <= 19).map(item => item.AantalWerknemers).reduce((accumulation, current) => {
                    return accumulation + current;
                }, 0);

                setOptions({
                    ...options,
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'round',
                        colors: undefined,
                        width: 5,
                        dashArray: 0,
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    chart: {
                        id: 'UniqueEmployersCombi',
                        toolbar: {
                            show: false
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            colors: ['#fff']
                        },
                        background: {
                            enabled: true,
                            foreColor: 'black',
                            padding: 4,
                            borderWidth: 1,
                            borderColor: 'black',
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            borderRadiusApplication: 'end',
                            dataLabels: {
                                position: 'bottom'
                            }
                        }
                    },
                    yaxis: {
                        forceNiceScale: true,
                        show: true,
                        labels: {
                            formatter: function (d: any) {
                                return d + "%"
                            }
                        },
                    },
                    series: [
                        {
                            name: 'Leeftijdsverdeling in Cursussen',
                            color: "#158A42",
                            type: 'bar',
                            data: [
                                //startJaar
                                {
                                    x: '13-19',
                                    y: (age13_19cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '20-24',
                                    y: (age20_24cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '25-20',
                                    y: (age25_29cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '30-34',
                                    y: (age30_34cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '35-39',
                                    y: (age35_39cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '40-44',
                                    y: (age40_44cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '45-49',
                                    y: (age45_49cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '50-54',
                                    y: (age50_54cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '55-59',
                                    y: (age55_59cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '60-64',
                                    y: (age60_64cur / ageTotalCur * 100).toFixed(0)
                                },
                                {
                                    x: '65+',
                                    y: (age65cur / ageTotalCur * 100).toFixed(0)
                                },
                            ]
                        },
                        {
                            name: 'Leeftijdsverdeling in Sector',
                            color: "#612812",
                            type: 'line',
                            data: [
                                //startJaar
                                {
                                    x: '13-19',
                                    y: (age13_19 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '20-24',
                                    y: (age20_24 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '25-20',
                                    y: (age25_29 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '30-34',
                                    y: (age30_34 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '35-39',
                                    y: (age35_39 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '40-44',
                                    y: (age40_44 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '45-49',
                                    y: (age45_49 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '50-54',
                                    y: (age50_54 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '55-59',
                                    y: (age55_59 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '60-64',
                                    y: (age60_64 / ageTotal * 100).toFixed(0)
                                },
                                {
                                    x: '65+',
                                    y: (age65 / ageTotal * 100).toFixed(0)
                                },
                            ]
                        }
                    ],
                });

                setBusy(false);
            }
            );
    }, [])

    if (busy)
    return <></>

  return (
    <>
      <div className="screen">
        <Chart options={options}
                  series={options?.series}
                  height="450"
               type="bar"/>
      </div>
      <div className="print">
        <Chart options={options}
               series={options?.series}
                  type="bar"
                  width="720"
                  height="450"
              />
      </div>
    </>
  )
}

export default CursusAgesEmployees;
