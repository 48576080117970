// Import libraries
import React, { useState } from "react";

// Import components
import Chart from "react-apexcharts";
import { DASH_ALG_WerkgeversAantalAanvragenPerJaar } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";
import { Col, Table } from "react-bootstrap";

function EmployersTop(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_ALG_WerkgeversAantalAanvragenPerJaar[] = [];

    let [busy, setBusy] = useState(true);
    let [empty, setEmpty] = useState(true);

    let [tabel, setTable] = useState("");

    fetch(FormatString(ApiUrls.DASH_ALG_WerkgeversAantalAanvragenPerJaar, ApiBaseURL, SectorId.toString(), DashboardYear.toString() + "-1-1", DashboardYear.toString() + "-12-31", params.RegelingId.toString(), loginGuid, userGuid, params.kw))
        .then(response => response.json())
        .then(json => {

            obj = JSON.parse(JSON.stringify(json));
            setEmpty(obj.length == 0);

            tabel = "";
            for (var i = 0; i < obj.length; i++) {
                tabel +=
                    "<tr><td align='left'>" + obj[i].Werkgever +
                    "</td><td>" + obj[i].AantalAanvragen.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) +
                    "</td></tr>";
            }
            setTable(tabel);
            setBusy(false);
        }
    );

    if (busy || empty)
    return <></>

    return (
         <Col xl={6} md={12} className="mb-2 col-print-6">
            <div className="card bg-white border border-gray-200 rounded-lg shadow p-3 h-auto">
                <h6 className="hfont6">Werkgevers met de meeste {params.RegelingId == 3 ? "unieke werknemers" : "declaraties"} {params.RegelingNaam} in {DashboardYear} </h6>
                <div className="table-responsive">
                    <Table hover size="sm" className="text-end mb-0 gras-table">
                        <thead>
                            <tr>
                                <th className="text-start">Werkgever</th>
                                <th>Aantal</th>
                            </tr>
                        </thead>
                        <tbody dangerouslySetInnerHTML={{ __html: tabel }} />
                    </Table>
                </div>
            </div>
        </Col>
  )
}

export default EmployersTop;
