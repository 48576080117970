// Import libraries
import React, { useState } from "react";

// Import components
import Chart from "react-apexcharts";
import { DASH_ALG_WerknemersMetSubsidieRegelingen } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";
import { Table } from "react-bootstrap";

function UniqueEmployeesTable(params: any) {
    var DashboardYear = params.DashboardYear;
    var SectorId = params.SectorId;
    var userGuid = params.rnd;
    var loginGuid = params.lg;

    let obj: DASH_ALG_WerknemersMetSubsidieRegelingen[] = [];

    let [busy, setBusy] = useState(true);

    let [tabel, setTable] = useState("");

    let jaar1 = DashboardYear - 3;
    let jaar2 = DashboardYear - 2;
    let jaar3 = DashboardYear - 1;
    let jaar4 = DashboardYear;

    fetch(FormatString(ApiUrls.DASH_ALG_WerknemersMetSubsidieRegelingen, ApiBaseURL, SectorId.toString(), jaar1.toString() + "-1-1", jaar4.toString() + "-12-31", loginGuid, userGuid, params.kw))
        .then(response => response.json())
        .then(json => {

            obj = JSON.parse(JSON.stringify(json));

            var objJaar1 = obj.filter(f => f.Jaar == jaar1);
            var objJaar2 = obj.filter(f => f.Jaar == jaar2);
            var objJaar3 = obj.filter(f => f.Jaar == jaar3);
            var objJaar4 = obj.filter(f => f.Jaar == jaar4);

            tabel = "";
            var row1total = 0;
            var row2total = 0;
            var row3total = 0;
            var row4total = 0;

            for (var i = 0; i < obj.length; i++) {
                var row1 = objJaar1[i];
                var row2 = objJaar2[i];
                var row3 = objJaar3[i];
                var row4 = objJaar4[i];

                if (row1 != null || row2 != null || row3 != null || row4 != null)
                {
                    tabel +=
                        "<tr><td align='left'>" + (row1 != null ? row1.Regeling : row2 != null ? row2.Regeling : row3 != null ? row3.Regeling : row4 != null ? row4.Regeling : "" ) +
                        "</td><td>" + ((row1?.AantalWerknemers ?? 0).toLocaleString('nl-NL', { maximumFractionDigits: 0 })) +
                        "</td><td>" + ((row2?.AantalWerknemers ?? 0).toLocaleString('nl-NL', { maximumFractionDigits: 0 })) +
                        "</td><td>" + ((row3?.AantalWerknemers ?? 0).toLocaleString('nl-NL', { maximumFractionDigits: 0 })) +
                        "</td><td>" + ((row4?.AantalWerknemers ?? 0).toLocaleString('nl-NL', { maximumFractionDigits: 0 })) +
                        "</td></tr>";

                    row1total += row1?.AantalWerknemers ?? 0;
                    row2total += row2?.AantalWerknemers ?? 0; 
                    row3total += row3?.AantalWerknemers ?? 0; 
                    row4total += row4?.AantalWerknemers ?? 0; 
                }
            }
            tabel +=
                "<tr><td align='left'><b>Totaal</b></td><td><b>" + row1total.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</b></td><td><b>" + row2total.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</b></td><td><b>" + row3total.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</td><td><b>" + row4total.toLocaleString('nl-NL', { maximumFractionDigits: 0 }) + "</b></td></tr>";
            setTable(tabel);
            setBusy(false);
        }
    );

    if (busy)
    return <></>

  return (
      <div className="table-responsive">
          <Table hover size="sm" className="text-end mb-0 gras-table">
              <thead>
                  <tr>
                      <th className="text-start">Naam regeling</th>
                      <th>{jaar1}</th>
                      <th>{jaar2}</th>
                      <th>{jaar3}</th>
                      <th>{jaar4}</th>
                  </tr>
              </thead>
              <tbody dangerouslySetInnerHTML={{ __html: tabel }} /> 
          </Table>
      </div>
  )
}

export default UniqueEmployeesTable;
